import { Terminal, IDisposable, ITerminalAddon } from 'xterm';
import { IokitSocket } from './socket';

export class WsAttachAddon implements ITerminalAddon {
  private disposables: IDisposable[] = [];
  private socket: IokitSocket;
  private terminal?: Terminal = undefined;

  constructor(socket: IokitSocket) {
    this.socket = socket;
  }

  activate(terminal: Terminal): void {
    this.terminal = terminal;
    const thiz = this;
    this.socket.setOnSerialInput((data: string) => {
      thiz.onSerialInput(data)
    });

    this.disposables.push(terminal.onData(data => this.socket.terminalInput(data)));
  }

  dispose(): void {
    this.disposables.forEach(d => d.dispose());
  }

  private onSerialInput(data: string) {
    const str = data.replace('\n', '\r\n');
    this.terminal?.write(str);
  }
}
