import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Terminal } from './components/terminal';
import { TerminalOverview } from './components/terminalOverview';

import './App.css';

export function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact path="/" to="/term/" />
        <Route exact path="/term/" component={TerminalOverview}/>
        <Route path="/term/:sessionId" component={Terminal} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
