interface OnSerialInputFunc {
  (data: string): void;
}

interface OnSerialOutputFunc {
  (data: string): void;
}

enum MsgType {
  MSG_TERM_INPUT_TO_SERVER = 1,
  MSG_SERIAL_INPUT_TO_SERVER = 2,
}

export class IokitSocket {

  private socket: WebSocket;

  private onSerialInput?: OnSerialInputFunc = undefined;
  private onSerialOutput?: OnSerialOutputFunc = undefined;

  constructor(sessionId: string) {
    const socketURL = 'wss://ws.iokit.net/term/' + sessionId;
    this.socket = new WebSocket(socketURL);
    this.socket.binaryType = 'arraybuffer';

    const thiz = this;
    this.socket.addEventListener('message',
      (event: MessageEvent<any>) => {
        thiz.onMessage(event)
      });
  }
  
  private onMessage(event: MessageEvent<any>) {
    const msg = JSON.parse(event.data);
    console.log(msg);
    switch (msg.a) {
      case MsgType.MSG_TERM_INPUT_TO_SERVER: {
        if (this.onSerialOutput !== undefined) {
          this.onSerialOutput(msg.d);
        }
        break;
      }
      case MsgType.MSG_SERIAL_INPUT_TO_SERVER: {
        if (this.onSerialInput !== undefined) {
          this.onSerialInput(msg.d);
        }
        break;
      }
      default: {
        console.log('unknown message', msg);
      }
    }
  }

  setOnSerialInput(func: OnSerialInputFunc) {
    this.onSerialInput = func;
  }

  setOnSerialOutput(func: OnSerialOutputFunc) {
    this.onSerialOutput = func;
  }

  serialInput(data: Uint8Array) {
    if (this.socket.readyState !== this.socket.OPEN) {
      console.warn('IokitSocket.serialInput: websocket not open')
      return;
    }
    const utf8Data = new TextDecoder('utf-8').decode(data);
    const msg = {a: MsgType.MSG_SERIAL_INPUT_TO_SERVER, d: utf8Data};
    this.socket.send(JSON.stringify(msg));
  }

  terminalInput(data: string) {
    if (this.socket.readyState !== this.socket.OPEN) {
      console.warn('IokitSocket.serialInput: websocket not open')
      return;
    }
    const msg = {a: MsgType.MSG_TERM_INPUT_TO_SERVER, d: data};
    this.socket.send(JSON.stringify(msg));
  }

}
