import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

// const emails = ['username@gmail.com', 'user02@gmail.com'];
// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

export interface ConnectSerialDialogProps {
  open: boolean;
//   selectedValue: string;
  onClose: (/*value: string*/) => void;
  onSelect: () => void;
}

export function ConnectSerialDialog(props: ConnectSerialDialogProps) {
//   const classes = useStyles();
  const { onClose, /*selectedValue,*/ onSelect, open } = props;

  const handleClose = () => {
    onClose(/*selectedValue*/);
  };

  const handleSelect = (e: any) => {
      onSelect();
  }

//   const handleListItemClick = (value: string) => {
    // onClose(value);
//   };

  return (
    <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        >
      <DialogTitle id="simple-dialog-title">Connect a serial device</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Start the terminal session by connecting to serial device.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSelect} color="primary">Select device</Button>
      </DialogActions>
      {/*<List>
        {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}
        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem>
        </List>*/}
    </Dialog>
  );
}

/*export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
      <br />
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}*/