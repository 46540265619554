import React, { useEffect } from 'react';
import { XTerm } from 'xterm-for-react';
import { FitAddon } from 'xterm-addon-fit';
import { WsAttachAddon } from '../lib/wsattach';
import { isSerialSupported } from '../lib/serial';
import { IokitSocket } from '../lib/socket';

import AppBar from '@material-ui/core/AppBar/AppBar';
// import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
// import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';
import { useParams } from 'react-router-dom';

import './terminal.css';

import { ConnectSerialDialog } from './connectSerial';

export function Terminal() {

  const { sessionId } = useParams<{ sessionId: string }>();

  const socket = new IokitSocket(sessionId);

  const fitAddon = new FitAddon();
  const wsAddon = new WsAttachAddon(socket);
  let port: SerialPort;

  // window.fitAddon = fitAddon;
  // setTimeout(() => { fitAddon.fit(); console.log('fit') }, 1000);

  useEffect(() => {
    fitAddon.fit();
  });

  const handleSelectSerialDevice = async () => {
    if (!isSerialSupported()) {
      alert('Web Serial API not available');
      return;
    }
    const filters = [
      { usbVendorId: 0x0483 },
      { usbVendorId: 0x0403 }
    ];
    port = await navigator.serial.requestPort({ filters });
    await port.open({baudRate: 9600});

    setOpenConnectSerialDialog(false);

    const writer = port?.writable.getWriter();
    socket.setOnSerialOutput((data: string) => {
      // port.
      const u8array = new TextEncoder().encode(data);
      writer?.write(u8array);
    });
    
    while (port && port.readable) {
      const reader = port.readable.getReader();
      for (;;) {
        const {value, done} = await reader.read();
        if (value) {
          socket.serialInput(value);
        }
        if (done) {
          break;
        }
      }
      reader.releaseLock();
    }
  };

  const [openConnectSerialDialog, setOpenConnectSerialDialog] = React.useState(true);

  // setOpenConnectSerialDialog(true);

  // const handleClickOpen = () => {
  //   setOpenConnectSerialDialog(true);
  // };

  const handleConnectSerialDialogClose = () => {
    setOpenConnectSerialDialog(false);
  }

  return (
      <div className="App">
        <AppBar position="static">
          <Toolbar variant="dense">
            {/*<IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>*/}
            <Typography variant="h6">
              Beata pm test
            </Typography>
            {/*<div>
              <IconButton
                onClick={handleClickOpen}
              >
                <Icon>power</Icon>
              </IconButton>
            </div>*/}
          </Toolbar>
        </AppBar>
        <ConnectSerialDialog
          open={openConnectSerialDialog}
          onClose={handleConnectSerialDialogClose}
          onSelect={handleSelectSerialDevice}
        />
        {/* <div> */}
          <XTerm
            addons={[fitAddon, wsAddon]}
            className={"meeep"}
          />
        {/* </div> */}
      </div>
    );
}