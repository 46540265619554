import React from 'react';

import AppBar from '@material-ui/core/AppBar/AppBar';
// import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
// import MenuIcon from '@material-ui/icons/Menu';
import Icon from '@material-ui/core/Icon';

export function TerminalOverview() {

  return (
      <div className="App">
        <AppBar position="static">
          <Toolbar variant="dense">
            {/*<IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>*/}
            <Typography variant="h6">
              Overview
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
}